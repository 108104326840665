<template>
  <div class="page_body">
    <div class="box">
      <div class="top_box">
        <div class="top_box_title">
          <div class="title">产品销售榜</div>
        </div>
      </div>
      <div class="content">
        <!-- 全省各市州采购排行 -->
        <div class="content_item">
          <div class="c_topbox">
            <el-row class="elbut_lst">
            </el-row>
            <div class="c_top_search">
              <el-input placeholder="请输入商家名称搜索" class="input-with-select">
                <el-button slot="append" icon="el-icon-search">搜索</el-button>
              </el-input>
            </div>
          </div>
          <div class="c_body">
            <el-table :data="tableData" style="width: 100%">
              <el-table-column align="center" label="序号" show-overflow-tooltip width="50">
                <template slot-scope="scope">
                  <span>{{scope.$index + 1 + (currentPage - 1) * pageLimit}}</span>
                </template>
              </el-table-column>
              <el-table-column align="center" label="图片" show-overflow-tooltip width="89">
                <template slot-scope="scope">
                  <el-image class="cb_box_img" :src="$img(scope.row.img)" fit="cover" />
                </template>
              </el-table-column>
              <el-table-column align="center" prop="title" label="产品名称">
              </el-table-column>
              <el-table-column align="center" prop="person_count" label="累计买过人次" width="160">
              </el-table-column>
              <el-table-column align="center" prop="amount" label="近一月售出件数" width="160">
              </el-table-column>
              <el-table-column align="center" prop="money_total" label="成交金额（元）" width="160">
              </el-table-column>
            </el-table>
            <div class="content_footer">
              <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
                :current-page="currentPage" :page-sizes="[10, 20, 50, 100, 200]" :page-size="pageLimit"
                layout="total, sizes, prev, pager, next, jumper" :total="tableDataGet.length">
              </el-pagination>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import {
    mapGetters
  } from 'vuex';
  export default {
    name: 'bigdata_fupin_catalog',
    components: {},
    data: () => {
      return {
        // 当前单位性质
        budgetOn: "不限",
        tableTitleDatas: [{
          name: '单位个数',
          untis: '（个）',
          value: 15,
        }, {
          name: '采购总金额',
          untis: '（万元）',
          value: 20182546.34,
        }, ],
        // 单位分类列表
        buttonlst: [{
          id: 1,
          type: 'primary',
          name: '不限',
        }, {
          id: 2,
          type: '',
          name: '预算单位',
        }, {
          id: 3,
          type: '',
          name: '非预算单位',
        }, ],
        // 扶贫目录列表
        tableData: [],
        tableDataGet: [{
          id: 1,
          title: "湖南新宁崀山脐橙普通箱9斤小果",
          img: "http://hngdxxfp.oss-cn-hangzhou.aliyuncs.com/upload/12478/common/images/20210817/20210817023909162918234919453_mid.jpg",
          person_count: 525486,
          amount: 3025485,
          money_total: 1587463.58,
        }, {
          id: 2,
          title: "湘益资压榨菜籽油4.5L",
          img: "http://hngdxxfp.oss-cn-hangzhou.aliyuncs.com/upload/12478/common/images/20210817/20210817023909162918234919453_mid.jpg",
          person_count: 525486,
          amount: 3025485,
          money_total: 1587463.58,
        }, {
          id: 3,
          title: "2021新茶萧氏明前新绿250g湖北茶叶绿茶散装高山云雾袋装",
          img: "http://hngdxxfp.oss-cn-hangzhou.aliyuncs.com/upload/12478/common/images/20210817/20210817023909162918234919453_mid.jpg",
          person_count: 525486,
          amount: 3025485,
          money_total: 1587463.58,
        }, {
          id: 4,
          title: "湘益资香米50斤",
          img: "http://hngdxxfp.oss-cn-hangzhou.aliyuncs.com/upload/12478/common/images/20210817/20210817023909162918234919453_mid.jpg",
          person_count: 525486,
          amount: 3025485,
          money_total: 1587463.58,
        }, {
          id: 5,
          title: "麻阳苗族自治县纯正浓香菜籽油5L现榨现卖食用油包邮",
          img: "http://hngdxxfp.oss-cn-hangzhou.aliyuncs.com/upload/12478/common/images/20210817/20210817023909162918234919453_mid.jpg",
          person_count: 525486,
          amount: 3025485,
          money_total: 1587463.58,
        }, {
          id: 6,
          title: "永顺县农家自种椪柑5kg装现摘甜蜜桔子橘子新鲜水果",
          img: "http://hngdxxfp.oss-cn-hangzhou.aliyuncs.com/upload/12478/common/images/20210817/20210817023909162918234919453_mid.jpg",
          person_count: 525486,
          amount: 3025485,
          money_total: 1587463.58,
        }, {
          id: 7,
          title: "永顺县农家自种椪柑5kg装现摘甜蜜桔子橘子新鲜水果",
          img: "http://hngdxxfp.oss-cn-hangzhou.aliyuncs.com/upload/12478/common/images/20210817/20210817023909162918234919453_mid.jpg",
          person_count: 525486,
          amount: 3025485,
          money_total: 1587463.58,
          id: 2,
          title: "湘益资压榨菜籽油4.5L",
          img: "http://hngdxxfp.oss-cn-hangzhou.aliyuncs.com/upload/12478/common/images/20210817/20210817023909162918234919453_mid.jpg",
          person_count: 525486,
          amount: 3025485,
          money_total: 1587463.58,
        }, {
          id: 3,
          title: "2021新茶萧氏明前新绿250g湖北茶叶绿茶散装高山云雾袋装",
          img: "http://hngdxxfp.oss-cn-hangzhou.aliyuncs.com/upload/12478/common/images/20210817/20210817023909162918234919453_mid.jpg",
          person_count: 525486,
          amount: 3025485,
          money_total: 1587463.58,
        }, {
          id: 4,
          title: "湘益资香米50斤",
          img: "http://hngdxxfp.oss-cn-hangzhou.aliyuncs.com/upload/12478/common/images/20210817/20210817023909162918234919453_mid.jpg",
          person_count: 525486,
          amount: 3025485,
          money_total: 1587463.58,
        }, {
          id: 5,
          title: "麻阳苗族自治县纯正浓香菜籽油5L现榨现卖食用油包邮",
          img: "http://hngdxxfp.oss-cn-hangzhou.aliyuncs.com/upload/12478/common/images/20210817/20210817023909162918234919453_mid.jpg",
          person_count: 525486,
          amount: 3025485,
          money_total: 1587463.58,
        }, {
          id: 6,
          title: "永顺县农家自种椪柑5kg装现摘甜蜜桔子橘子新鲜水果",
          img: "http://hngdxxfp.oss-cn-hangzhou.aliyuncs.com/upload/12478/common/images/20210817/20210817023909162918234919453_mid.jpg",
          person_count: 525486,
          amount: 3025485,
          money_total: 1587463.58,
        }, {
          id: 7,
          title: "永顺县农家自种椪柑5kg装现摘甜蜜桔子橘子新鲜水果",
          img: "http://hngdxxfp.oss-cn-hangzhou.aliyuncs.com/upload/12478/common/images/20210817/20210817023909162918234919453_mid.jpg",
          person_count: 525486,
          amount: 3025485,
          money_total: 1587463.58,
          id: 2,
          title: "湘益资压榨菜籽油4.5L",
          img: "http://hngdxxfp.oss-cn-hangzhou.aliyuncs.com/upload/12478/common/images/20210817/20210817023909162918234919453_mid.jpg",
          person_count: 525486,
          amount: 3025485,
          money_total: 1587463.58,
        }, {
          id: 3,
          title: "2021新茶萧氏明前新绿250g湖北茶叶绿茶散装高山云雾袋装",
          img: "http://hngdxxfp.oss-cn-hangzhou.aliyuncs.com/upload/12478/common/images/20210817/20210817023909162918234919453_mid.jpg",
          person_count: 525486,
          amount: 3025485,
          money_total: 1587463.58,
        }, {
          id: 4,
          title: "湘益资香米50斤",
          img: "http://hngdxxfp.oss-cn-hangzhou.aliyuncs.com/upload/12478/common/images/20210817/20210817023909162918234919453_mid.jpg",
          person_count: 525486,
          amount: 3025485,
          money_total: 1587463.58,
        }, {
          id: 5,
          title: "麻阳苗族自治县纯正浓香菜籽油5L现榨现卖食用油包邮",
          img: "http://hngdxxfp.oss-cn-hangzhou.aliyuncs.com/upload/12478/common/images/20210817/20210817023909162918234919453_mid.jpg",
          person_count: 525486,
          amount: 3025485,
          money_total: 1587463.58,
        }, {
          id: 6,
          title: "永顺县农家自种椪柑5kg装现摘甜蜜桔子橘子新鲜水果",
          img: "http://hngdxxfp.oss-cn-hangzhou.aliyuncs.com/upload/12478/common/images/20210817/20210817023909162918234919453_mid.jpg",
          person_count: 525486,
          amount: 3025485,
          money_total: 1587463.58,
        }, {
          id: 7,
          title: "永顺县农家自种椪柑5kg装现摘甜蜜桔子橘子新鲜水果",
          img: "http://hngdxxfp.oss-cn-hangzhou.aliyuncs.com/upload/12478/common/images/20210817/20210817023909162918234919453_mid.jpg",
          person_count: 525486,
          amount: 3025485,
          money_total: 1587463.58,
          id: 2,
          title: "湘益资压榨菜籽油4.5L",
          img: "http://hngdxxfp.oss-cn-hangzhou.aliyuncs.com/upload/12478/common/images/20210817/20210817023909162918234919453_mid.jpg",
          person_count: 525486,
          amount: 3025485,
          money_total: 1587463.58,
        }, {
          id: 3,
          title: "2021新茶萧氏明前新绿250g湖北茶叶绿茶散装高山云雾袋装",
          img: "http://hngdxxfp.oss-cn-hangzhou.aliyuncs.com/upload/12478/common/images/20210817/20210817023909162918234919453_mid.jpg",
          person_count: 525486,
          amount: 3025485,
          money_total: 1587463.58,
        }, {
          id: 4,
          title: "湘益资香米50斤",
          img: "http://hngdxxfp.oss-cn-hangzhou.aliyuncs.com/upload/12478/common/images/20210817/20210817023909162918234919453_mid.jpg",
          person_count: 525486,
          amount: 3025485,
          money_total: 1587463.58,
        }, {
          id: 5,
          title: "麻阳苗族自治县纯正浓香菜籽油5L现榨现卖食用油包邮",
          img: "http://hngdxxfp.oss-cn-hangzhou.aliyuncs.com/upload/12478/common/images/20210817/20210817023909162918234919453_mid.jpg",
          person_count: 525486,
          amount: 3025485,
          money_total: 1587463.58,
        }, {
          id: 6,
          title: "永顺县农家自种椪柑5kg装现摘甜蜜桔子橘子新鲜水果",
          img: "http://hngdxxfp.oss-cn-hangzhou.aliyuncs.com/upload/12478/common/images/20210817/20210817023909162918234919453_mid.jpg",
          person_count: 525486,
          amount: 3025485,
          money_total: 1587463.58,
        }, {
          id: 7,
          title: "永顺县农家自种椪柑5kg装现摘甜蜜桔子橘子新鲜水果",
          img: "http://hngdxxfp.oss-cn-hangzhou.aliyuncs.com/upload/12478/common/images/20210817/20210817023909162918234919453_mid.jpg",
          person_count: 525486,
          amount: 3025485,
          money_total: 1587463.58,
          id: 2,
          title: "湘益资压榨菜籽油4.5L",
          img: "http://hngdxxfp.oss-cn-hangzhou.aliyuncs.com/upload/12478/common/images/20210817/20210817023909162918234919453_mid.jpg",
          person_count: 525486,
          amount: 3025485,
          money_total: 1587463.58,
        }, {
          id: 3,
          title: "2021新茶萧氏明前新绿250g湖北茶叶绿茶散装高山云雾袋装",
          img: "http://hngdxxfp.oss-cn-hangzhou.aliyuncs.com/upload/12478/common/images/20210817/20210817023909162918234919453_mid.jpg",
          person_count: 525486,
          amount: 3025485,
          money_total: 1587463.58,
        }, {
          id: 4,
          title: "湘益资香米50斤",
          img: "http://hngdxxfp.oss-cn-hangzhou.aliyuncs.com/upload/12478/common/images/20210817/20210817023909162918234919453_mid.jpg",
          person_count: 525486,
          amount: 3025485,
          money_total: 1587463.58,
        }, {
          id: 5,
          title: "麻阳苗族自治县纯正浓香菜籽油5L现榨现卖食用油包邮",
          img: "http://hngdxxfp.oss-cn-hangzhou.aliyuncs.com/upload/12478/common/images/20210817/20210817023909162918234919453_mid.jpg",
          person_count: 525486,
          amount: 3025485,
          money_total: 1587463.58,
        }, {
          id: 6,
          title: "永顺县农家自种椪柑5kg装现摘甜蜜桔子橘子新鲜水果",
          img: "http://hngdxxfp.oss-cn-hangzhou.aliyuncs.com/upload/12478/common/images/20210817/20210817023909162918234919453_mid.jpg",
          person_count: 525486,
          amount: 3025485,
          money_total: 1587463.58,
        }, {
          id: 7,
          title: "永顺县农家自种椪柑5kg装现摘甜蜜桔子橘子新鲜水果",
          img: "http://hngdxxfp.oss-cn-hangzhou.aliyuncs.com/upload/12478/common/images/20210817/20210817023909162918234919453_mid.jpg",
          person_count: 525486,
          amount: 3025485,
          money_total: 1587463.58,
        }, {
          id: 8,
          title: "永顺县农家自种椪柑5kg装现摘甜蜜桔子橘子新鲜水果",
          img: "http://hngdxxfp.oss-cn-hangzhou.aliyuncs.com/upload/12478/common/images/20210817/20210817023909162918234919453_mid.jpg",
          person_count: 525486,
          amount: 3025485,
          money_total: 1587463.58,
        }, {
          id: 9,
          title: "永顺县农家自种椪柑5kg装现摘甜蜜桔子橘子新鲜水果",
          img: "http://hngdxxfp.oss-cn-hangzhou.aliyuncs.com/upload/12478/common/images/20210817/20210817023909162918234919453_mid.jpg",
          person_count: 525486,
          amount: 3025485,
          money_total: 1587463.58,
        }, {
          id: 10,
          title: "永顺县农家自种椪柑5kg装现摘甜蜜桔子橘子新鲜水果",
          img: "http://hngdxxfp.oss-cn-hangzhou.aliyuncs.com/upload/12478/common/images/20210817/20210817023909162918234919453_mid.jpg",
          person_count: 525486,
          amount: 3025485,
          money_total: 1587463.58,
        }, ],
        currentPage: 1,
        pageLimit: 10,
      };
    },
    created() {},
    computed: {
      ...mapGetters(['defaultHeadImage', 'defaultGoodsImage'])
    },
    watch: {
      catalogValue(olddata, newdata) {
        console.log("catalogValue...", olddata, newdata);
      }
    },
    mounted() {
      this.getTableData();
    },
    methods: {
      // 修改按钮状态
      changeBut(option) {
        this.buttonlst.map((item, index) => {
          item.type = '';
          if (item.id === option.id) {
            item.type = 'primary';
          }
        })
      },
      // 跳转到对应的链接
      toPage(option) {
        console.log("...toPage...", option);
      },
      handleSizeChange(val) {
        console.log(`每页 ${val} 条`);
        this.pageLimit = val;
        this.getTableData();
      },
      handleCurrentChange(val) {
        console.log(`当前页: ${val}`);
        this.currentPage = val;
        this.getTableData();
      },
      getTableData() {
        this.tableData = this.tableDataGet.slice((this.currentPage - 1) * this.pageLimit, this.currentPage * this
          .pageLimit);
      },
    },
  };
</script>
<style lang="scss" scoped>
  $color_font0: #797979;
  $color_font1: #555555;
  $color_font20: #3d3d3d;
  $color_font2: #303030;
  $color_waring: #D81E01;

  .el-main {
    position: relative;
  }

  .page_body {
    box-sizing: border-box;
    width: 100% !important;
    background-color: rgba(247, 247, 247);
    background-image: url('/public/static/img/bj.jpg');
    background-repeat: no-repeat;
    background-size: 100% 360px;
  }

  .box {
    max-width: $width;
    margin: 0 auto;
    padding-bottom: 50px;
    box-sizing: border-box;

    .top_box {
      padding-top: 30px;
      padding-bottom: 30px;
      color: #FFFFFF;
      box-sizing: border-box;

      .top_box_select {
        text-align: right;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        padding-bottom: 30px;

        .el_select {
          width: 360px;
        }
      }

      .top_box_content {
        box-sizing: border-box;
        padding: 20px 40px 0 40px;
        text-indent: 25px;
      }

      .top_box_title {
        box-sizing: border-box;
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        padding: 0 20px;

        .title {
          font-size: 24px;
          width: 1090px;
        }

        .date {
          font-size: 14px;
          width: 260px;
          text-align: right;
        }
      }
    }

    .content {
      width: 1210px;
      box-sizing: border-box;

      .content_item {
        box-sizing: border-box;

        .c_topbox {
          height: 108px;
          line-height: 68px;
          font-size: 16px;
          background-color: rgba(232, 238, 247);
          padding: 20px;
          border-top-left-radius: 8px;
          border-top-right-radius: 8px;
          display: flex;
          justify-content: space-between;
          box-sizing: border-box;

          .c_top_select {
            width: auto;
            padding-right: 40px;
            font-size: 14px;
          }

          .c_top_search {
            width: 390px;
          }

          .c_title_l {
            display: flex;
            align-items: center;

            span {
              color: #ff0000;
            }

            .title_img {
              margin-left: 20px;
              width: 26px !important;
              height: 20px !important;

              img {
                width: 26px !important;
                height: 20px !important;
              }
            }
          }
        }

        .c_body {
          box-sizing: border-box;
          background-color: #FFFFFF;
          padding: 20px;
          border-bottom-left-radius: 8px;
          border-bottom-right-radius: 8px;

          .c_b_top {
            width: 100%;
            display: flex;
            justify-content: flex-end;
            margin: 10px 0 30px 0;

            .top_item {
              width: 288px;
              display: flex;
              flex-direction: column;

              .number {
                text-align: center;
                font-size: 38px;
                font-weight: 800;
                color: rgba(114, 114, 114);
                font-stretch: condensed;
                line-height: 40px;
              }

              .names {
                text-align: center;
                line-height: 24px;
              }
            }
          }

          .cb_box {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
          }

          .content_footer {
            height: 108px;
            display: flex;
            justify-content: center;
            align-items: center;
          }
        }
      }
    }
  }
</style>